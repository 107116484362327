//MATERIAL
//$.material.init();
//LEFT MENU

$('#nestable').nestable({
    group: 0,
    maxDepth: 5
});
$('#nestable2').nestable({
    group: 0,
    maxDepth: 5
});
$('.dd').nestable('collapseAll');



//FORM SELECT
$("select").dropdown({
    "autoinit": "select"
});

//TOOLTIPY

// $('[data-toggle="tooltip"]').tooltip();

componentHandler.upgradeDom();


//VARIABLES
var sortProductsBoxBool = false;
$('#sortProductsBox').css('display', 'none');

//DOCUMENT READY
$(document).ready(function () {

    window.onscroll = function () {
        scrollFunction();
    };

    // SORTING MODULE
    function scrollFunction() {
        if ($('body,html').scrollTop() > 200 || $('.filtr-widget').scrollTop() > 200) {
            $('.filtr-widget').css('display', 'block');
        } else {
            $('.filtr-widget').css('display', 'none');
        }

        /*button going up */
        if ($('html')[0].scrollTop > 60 || $(':root')[0].scrollTop > 60) {
            $("#btn-going-top").show();
        } else {
            $("#btn-going-top").hide();
        }
    }
    $( "#sortProductsBoxButton" ).click(function() {
        if(sortProductsBoxBool == false){
            $('#sortProductsBox').css('display', 'block');
            if ($(window).width() <= 767) {
                $('body').css({'overflow-y': 'hidden', 'touch-action': 'none', '-ms-touch-action': 'none', 'position': 'fixed'});
            }
            sortProductsBoxBool = true;
        } else{
            $('#sortProductsBox').css('display', 'none');
            if ($(window).width() <= 767) {
                $('body').css({'overflow-y': 'auto', 'touch-action': 'inherit', '-ms-touch-action': 'inherit', 'position': 'unset'});
            }
            sortProductsBoxBool = false;
        }

    });
    $( ".exit-button" ).click(function() {
        if(sortProductsBoxBool == true){
            $('#sortProductsBox').css('display', 'none');
            if ($(window).width() <= 767) {
                $('body').css({'overflow-y': 'auto', 'touch-action': 'inherit', '-ms-touch-action': 'inherit', 'position': 'unset'});
            }
            sortProductsBoxBool = false;
        }
    });
    $( ".filtr-widget" ).click(function() {
        if(sortProductsBoxBool == false){
            $('#sortProductsBox').css('display', 'block');
            if ($(window).width() <= 767) {
                $('body').css({'overflow-y': 'hidden', 'touch-action': 'none', '-ms-touch-action': 'none', 'position': 'fixed'});
            }
            sortProductsBoxBool = true;
        }
    });
    // END SORTING MODULE

    $( "#expand-categories-mobile" ).click(function() {
        $('ul.expanded-menu-mobile').toggleClass('expanded');

        if ($( "#expand-categories-mobile" ).hasClass('fa-chevron-right')) {
            $( "#expand-categories-mobile" ).removeClass('fa-chevron-right').addClass('fa-chevron-down');
        } else {
            $( "#expand-categories-mobile" ).removeClass('fa-chevron-down').addClass('fa-chevron-right');
        }
    });


    // HOMEPAGE SELECTABLE CAROUSELS
    $(".homepage-promo-block .categories-slider-home").on("click", function(event) {
        let carouselItems = $(".homepage-owl-carousel-wrapper .homepage-owl-carousel");
            //Set current button to active and toggle others off
        $(".categories-slider-home").removeClass('active');
        $(this).addClass('active');
        carouselItems.removeClass('hidden');

        switch(event.currentTarget.id) {
            case 'bestsellery-btn':
                carouselItems.hide();
                $("#bestsellers-carousel").show();
                return;
            case 'promocje-btn':
                carouselItems.hide();
                $("#promo-carousel").show();
                return;
            case 'nowosci-btn':
                carouselItems.hide();
                $("#new-products-carousel").show();
                return;
            default:
                carouselItems.hide();
                $("#bestsellers-carousel").show();
                return;
        }
    });
    // END HOMEPAGE SELECTABLE CAROUSELS

    //USER ACCOUNT MENU
    $('.user-info-orders').hide();
    $('.user-info-wishlist').hide();

    $(".user-info-btns button").on("click", function(event) {
        const contents = $('.user-info-content');
        $(".user-info-btns button").removeClass('active');
        $(this).addClass('active');

        switch(event.currentTarget.id) {
            case 'account-btn':
                contents.hide();
                $(".user-info-account").show();
                return;
            case 'orders-btn':
                contents.hide();
                $(".user-info-orders").show();
                return;
            default:
                contents.hide();
                $(".user-info-account").show();
                return;
        }
    });
    //END USER ACCOUNT MENU

    var marginBlog = $('.blog-header').outerHeight();
    $('.article-home').first().css('margin-top', -marginBlog);
    $('.article-home').last().css('margin-top', -marginBlog);

    var boxProduct = $(".productsSlider");
    $(document).ready(function(){
        boxProduct.owlCarousel({
            items: 3,
            stagePadding: 1350,
            itemsDesktop : [2000,3],
            itemsDesktopSmall : [1250,2],
            itemsTablet: [1000,1],
            itemsTabletSmall: true,
            itemsMobile : [680,1],
            itemsMobileSmall: true,
            autoWidth: false,
            autoHeight:	false,
            loop:true,
            navRewind: false,
            autoPlay: 411000,
            pagination: false,
            navigation:true,
            nestedItemSelector: 'slide',
            navigationText : [''],
        });
    });

    /* Product cart gallery  */
    $('#productSlider').lightSlider({
        gallery: true,
        item: 1,
        loop: true,
        vertical:true,
        margins: 5,
        thumbItem:5,
        vThumbWidth:100,
        vThumbHeight:1,
        thumbMargin:0,
        slideMargin: 0,
        enableDrag: true,
        responsive : [
            {
                breakpoint:800,
                settings: {
                }
            },
        ],
        currentPagerPosition: 'center',
        currentPagerPosition: 'left',
        onSliderLoad: function (el) {
            el.lightGallery({
                selector: '#productSlider .lslide'
            });
        }

    });



    $('#demo-menu-lower-right').siblings(".mdl-menu__container").addClass('dropdown-profil');
    $('#marki-dropdown').siblings(".mdl-menu__container").addClass('dropdown-marki');
    $('.dropdown-marki').children(".mdl-menu__outline").addClass('dropdown-marki-ul');
    $('.dropdown-marki').children(".mdl-menu").addClass('dropdown-marki-ul-menu');


    $('.dropdown-profil').children(".mdl-menu__outline").addClass('dropdown-profil-ul');
    $('.dropdown-profil').children(".mdl-menu").addClass('dropdown-profil-ul-menu');



    $('.naglowek_porady > a > span ').each(function (index, value) {

        var  width_100 =  $(this).parent().parent().parent().width();
        var  width = $(this).parent().parent().width();
        var margin  = (Math.round( width_100 - width )/2 ).toString() ;

        $(this).parent().parent().css('left', margin+'px' );

        var height = $(this).height();
        if( height < 42 ){
            $(this).css('line-height','42px')
        }

        width_100 = null ;
        width = null ;
        margin = null ;
    });



    /*checkbox regulamin */
    var form = document.getElementById('zapis_zamowienia');
    if(form != null){
    form.noValidate = true;
    form.addEventListener('submit', function(event) {
        if (!event.target.checkValidity()) {
            event.preventDefault();
            alert('Zaakceptuj regulamin i politykę prywatności'); // error message
            $("#zamawiam").prop('disabled', false);
        }else{
            $("#zamawiam").prop('disabled', true);
        }
    }, false);
}

    $("#zamawiam").click(function (event){
        /* $(this).siblings(".mdl-textfield").addClass('is-invalid');*/


    });
//SZUKANIE
    /*
    function log(e) {
        if (e) {
            location.href = HTTP_PATH + '/produkt/' + e.params.data.SKL_SLG + ',' + e.params.data.id + '.html';
        }
    }

    $(".search_input").on("select2:select", function (e) {
        log(e);
    });

    function formatRepo(repo) {
        if (repo.loading)
            return repo.text;
        var url = HTTP_PATH + "/produkt/" + repo.SKL_SLG + "," + repo.id + ".html";
        var public_path = HTTP_PATH;
        var markup = "<div class='search_all ' style='min-height:80px;'>";

        markup += "<div style='width:20%;display:inline-block;min-height:80px;float:left'>" +
        "<img class='obrazek_search' src='" + public_path + "/produkty_zdjecia/" + repo.SKL_IMG + ".jpg" + "'/></div>" +
        "<div class='tytul_search roboto header_blue'>" + repo.SKL_NAZ + "<br>" + "</div>" +
        "<div class='cena_search roboto header_blue'>" + repo.SKL_CEN + " zł</div>";
        markup += "</div>";

        return markup;
    }

    function formatRepoSelection(repo) {
        return repo.SKL_SKL || repo.SKL_NAZ;
    }
*/
//wyszukiwarka
    var uerel = window.location.href ;

    if (uerel.indexOf("blog") !=-1) {

        var towary = {
            url: function (phrase) {
                return HTTP_PATH + "/artykuly/wyszukiwarka/" + phrase;
            },
            //listLocation: "items",
            getValue: "BLG_TIT",
            template: {
                type: "custom",
                method: function (value, item) {
                    var url_img = HTTP_PATH + '/css/images/esy-brak-zdjecia.jpg';
                    if (item.BLG_IMG != '') {
                        url_img = HTTP_PATH + '/blog_zdjecia/' + item.BLG_IMG + '.jpg';
                        // url_img = HTTP_PATH + '/css/images/esy-brak-zdjecia.jpg';
                    }

                /*    var cena = item.SKL_CEN;
                    if (Date.parse(item.SKL_PRS) && Date.parse(item.SKL_PRE)) {
                        var today = new Date().setHours(0, 0, 0, 0);
                        var start = new Date(item.SKL_PRS).setHours(0, 0, 0, 0);
                        var end = new Date(item.SKL_PRE).setHours(0, 0, 0, 0);
                        if (today >= start && today <= end) {
                            cena = item.SKL_PRC;
                        }
                    }*/

                    return "<a href='" + HTTP_PATH + '/artykul/' + item.BLG_SLG + "," + item.id + ".html' " +
                        "class='search-a roboto' style='display:block; text-decoration: none; width:100%'>" +
                        "<img src='" + url_img + "' style='width:54px;height:47px; margin-right: 5px; float:left'><div class='szukaj_tekst' style='font-weight: normal;float:left;'>" + value + "</div>" +
                        "<div class='roboto header_blue' style='float:right;text-align:right;display:inline-block;color:black;width:70px; font-weight:bold;min-height:40px;font-size:16px;padding-top:5px;'>" + cena + " zł</div></a><div style='padding: 0 0; margin: 0 0; clear: left'></div>";
                }
            }
            // requestDelay: 500
        };
    }else{

        var towary = {
            url: function (phrase) {
                return HTTP_PATH + "/produkty/wyszukiwarka/" + phrase;
            },
            //listLocation: "items",
            getValue: "SKL_SKL",
            list: {
                onChooseEvent: function() {
                    var item = $(".search_input").getSelectedItemData();
                    location.href = HTTP_PATH + "/produkt/" + item.SKL_SLG + "," + item.id + ".html";

                }/*,
                maxNumberOfElements: 4,
                match: {
                    enabled: true
                }*/
            },
            template: {
                type: "custom",
                method: function (value, item) {
                    var url_img = HTTP_PATH + '/css/images/esy-brak-zdjecia.jpg';
                    var exi  = item.SKL_EXI
                    if (exi != false) {
                        url_img = exi;
                        // url_img = HTTP_PATH + '/css/images/esy-brak-zdjecia.jpg';
                    }else if (item.SKL_IMG != '') {
                        url_img = HTTP_PATH + '/produkty_zdjecia/' + item.SKL_IMG + '.jpg';
                        // url_img = HTTP_PATH + '/css/images/esy-brak-zdjecia.jpg';
                    }

                    var cena = item.SKL_CEN;
                    if (Date.parse(item.SKL_PRS) && Date.parse(item.SKL_PRE)) {
                        var today = new Date().setHours(0, 0, 0, 0);
                        var start = new Date(item.SKL_PRS).setHours(0, 0, 0, 0);
                        var end = new Date(item.SKL_PRE).setHours(0, 0, 0, 0);
                        if (today >= start && today <= end) {
                            cena = item.SKL_PRC;
                        }
                    }

                    return "<a href='" + HTTP_PATH + '/produkt/' + item.SKL_SLG + "," + item.id + ".html' " +
                        "class='search-a roboto' style='display:block; text-decoration: none; width:100%'>" +
                        "<img src='" + url_img + "' style='width:54px;height:47px; margin-right: 5px; float:left'><div class='szukaj_tekst' style='font-weight: normal;float:left;'>" + value + "</div>" +
                        "<div class='roboto header_blue' style='float:right;text-align:right;display:inline-block;color:black;width:70px; font-weight:bold;min-height:40px;font-size:16px;padding-top:5px;'>" + cena + " zł</div></a><div style='padding: 0 0; margin: 0 0; clear: left'></div>";
                }
            }
            // requestDelay: 500
        };

    }

    $(".search_input").easyAutocomplete(towary);

    //stars
/*
    var options = {
        max_value: 5,
        step_size: 1
    };
    $(".rating").rate(options);
    $(".rating span").addClass("intro");
*/


        $('.radio_dostawa').each(function (index, value) {

            if( this.checked == true ) {
                $(this).siblings().addClass('header_blue');
            }

        });



    //hide show order
    $(".order-history .mdl-navigation__link").click(function () {
        $('.mdl-accordion').not(this).removeClass("mdl-accordion--opened");
    });
    $(".zwin").click(function () {
        $('.mdl-accordion').removeClass("mdl-accordion--opened");
    });

    $(".menu_nawigacja").click(function () {

        $( ".menu_nawigacja").removeClass('aktywny');

        $(this).addClass('aktywny');

    });




    //Działania globalne w całej aplikacji
    var value = 0;

    //product card plus & minus
    $('.qtyplus').click(function (e) {
        e.preventDefault();
        fieldName = $(this).attr('field');
        var currentVal = parseInt($('input[name=' + fieldName + ']').val());
        if (!isNaN(currentVal)) {
           var max = $('input[name=' + fieldName + ']').attr('max');
            if(currentVal >=  max){
            }else{
            $('input[name=' + fieldName + ']').val(currentVal + 1);
              }
        } else {
            $('input[name=' + fieldName + ']').val(0);
        }
    });
    $(".qtyminus").click(function (e) {
        e.preventDefault();
        fieldName = $(this).attr('field');
        var currentVal = parseInt($('input[name=' + fieldName + ']').val());
        if (!isNaN(currentVal) && currentVal > 1) {
            $('input[name=' + fieldName + ']').val(currentVal - 1);
        } else {
            $('input[name=' + fieldName + ']').val(1);
        }
    });


    $(".mdl-layout__obfuscator").click(function () {


        $("body").removeClass('lock');
        $(".mdl-layout__drawer").removeClass('anti-lock');
        value = 0;
    });


    $(".mdl-layout__drawer-button").click(function () {


        if (value == 0) {
            $("body").addClass('lock');
            $(".mdl-layout__drawer").addClass('anti-lock');
            // alert('value == 0');
            value++;
        } else {
            $("body").removeClass('lock');
            //    alert('value == 1');
            value = 0;
        }


    });


    $('#okazje').parent().css('pointer-events', 'none');


    var i = 0;
    var timer;
    var yes = 0 ;
    var remember = null  ;


    $(".menu_pionowe_tabs").hover(function () {

        var value = $(this).attr('data-rel');
        $('.divek').hide();
        $('.menu_pionowe_tabs').removeClass('activ');
        $('.menu_pionowe_tabs').addClass('color_blue');
        $('.arrow_menu').removeClass('widoczny');

        if ($(this).attr("data-rel") != 'bestseller-slug' && $(this).attr("data-rel") != 'na-prezent-slug' && $(this).attr("data-rel") != 'nowosci-slug') {

            $(this).addClass('activ');
            $(this).removeClass('color_blue');

            var value = $(this).attr('data-rel');
            var val = '#' + value;

            //  if ($(this).hasClass('activ') === false) {
            //  $('body').css('overflow','hidden');
            if(yes == 0 ){
                yes = 1 ;
                remember = 1 ;
                $(this).children().addClass('widoczny');
                timer = setTimeout(function () {


                    $(val).show();

                    $('#shadow-box').show();

                }, 800);

            }else{
                $(this).addClass('activ');
                $(this).removeClass('color_blue');
                $(this).children().addClass('widoczny');
                $(val).show();
                $('#shadow-box').show();

            }

        }





      //  } else {
         //   $('body').css('overflow', 'visible');
           // $('.divek').hide();
           // $('.menu_pionowe_tabs').removeClass('activ');

      //  }





    }, function (){

        var rel = $(this).attr('data-rel');


        $('.menu_pionowe_tabs').removeClass('activ');
        $('.menu_pionowe_tabs').addClass('color_blue');
        $(this).children().removeClass('widoczny');

        clearTimeout(timer);
            $(".divek").hover(
                    function () {

                        $( ".menu_pionowe_tabs" ).each(function( index ) {
                            //   console.log( index + ": " + $( this ).text() );

                            if ( $(this).attr('data-rel') == rel ){
                                $(this).children().addClass('widoczny');
                                $(this).addClass('activ');
                                $(this).removeClass('color_blue');
                            }else{
                                $(this).children().removeClass('widoczny');
                                $(this).removeClass('activ');
                                $(this).addClass('color_blue');
                            }
                        });
                        rel = null ;
                },
                function () {
                   $('.divek').hide();
                    $('#shadow-box').hide();
                        $('.menu_pionowe_tabs').removeClass('activ');
                        $('.menu_pionowe_tabs').addClass('color_blue');
                     $('.arrow_menu').removeClass('widoczny');

                }
            );


    });

    $("#menu_gorne").hover(function () {

            $(this).addClass('act');
            yes = 1 ;
        },
        function () {
            $(this).removeClass('act');
            yes = 0;
            $('.divek').hide();
            $('#shadow-box').hide();
        }
    );

    $(".info1").hover(function () {
            $('#info1_div_hover').addClass('widoczny');
            $('#info1_div').addClass('niewidoczny')
        },
        function () {
            $('#info1_div_hover').removeClass('widoczny');
            $('#info1_div').removeClass('niewidoczny')
        }
    );
    $(".info2").hover(function () {
            $('#info2_div_hover').addClass('widoczny');
            $('#info2_div').addClass('niewidoczny')
        },
        function () {
            $('#info2_div_hover').removeClass('widoczny');
            $('#info2_div').removeClass('niewidoczny')
        }
    );
    $(".info3").hover(function () {
            $('#info3_div_hover').addClass('widoczny');
            $('#info3_div').addClass('niewidoczny')
        },
        function () {
            $('#info3_div_hover').removeClass('widoczny');
            $('#info3_div').removeClass('niewidoczny')
        }
    );
    $(".info4").hover(function () {
            $('#info4_div_hover').addClass('widoczny');
            $('#info4_div').addClass('niewidoczny')
        },
        function () {
            $('#info4_div_hover').removeClass('widoczny');
            $('#info4_div').removeClass('niewidoczny')
        }
    );
    $(".info5").hover(function () {
            $('#info5_div_hover').addClass('widoczny');
            $('#info5_div').addClass('niewidoczny')
        },
        function () {
            $('#info5_div_hover').removeClass('widoczny');
            $('#info5_div').removeClass('niewidoczny')
        }
    );
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
   }else{
     /*   setTimeout(function () {
            var wysokosc = $("#baner_dol").height();
            $("#body_all").css('padding-bottom', wysokosc);
        }, 3000);*/
    }
    $(window).resize(function () {
      /*  var wysokosc = $("#baner_dol").height();
        $("#body_all").css('padding-bottom', wysokosc);
*/

        $('.naglowek_porady > a > span ').each(function (index, value) {

            var  width_100 =  $(this).parent().parent().parent().width();
            var  width = $(this).parent().parent().width();
            var margin  = (Math.round( width_100 - width )/2 ).toString() ;

            $(this).parent().parent().css('left', margin+'px' );

            var height = $(this).height();
            if( height < 42 ){
                $(this).css('line-height','42px')
            }

            width_100 = null ;
            width = null ;
            margin = null ;



        });
    });

    $( window ).on( "orientationchange", function( event ) {
        $('.naglowek_porady > a > span ').each(function (index, value) {

            var  width_100 =  $(this).parent().parent().parent().width();
            var  width = $(this).parent().parent().width();
            var margin  = (Math.round( width_100 - width )/2 ).toString() ;

            $(this).parent().parent().css('left', margin+'px' );

            var height = $(this).height();
            if( height < 42 ){
                $(this).css('line-height','42px')
            }

            width_100 = null ;
            width = null ;
            margin = null ;



        });
    });


    var pixs = $(document).scrollTop();

/*
    $(window).scroll(function () {
        shittyBlur();
    });
*/

});

//KOSZYK - PROGRESSBAR
function setIntervalProgressBar(param) {
    var $bar = $('.cart-progress-bar');
    $bar.width($bar.width() + param + '%');
}

function setBlur(radius) {
    var normal = 'blur(' + radius + 'px)';
    var ie = 'DXImageTransform.Microsoft.Blur(PixelRadius=\'' + radius + '\')';
    var img = $('#baner_dol');
    img.css({
        '-webkit-filter': normal,
        filter: normal,
        'filter:progid': ie,
        '-ms-filter:progid': ie
    });
}
/*
function getScrollY() {
    var supportPageOffset = window.pageYOffset !== undefined;
    var isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

}

function shittyBlur() {
    var scrollY = this.getScrollY(),
            windowHeight = $(window).height();
    var percent;
    var radius;
    var wysokosc = $("#baner_dol").height();
    var maxRadius = 8;
    if (scrollY + windowHeight > $('#body_all').height()) {
        percent = Math.floor(((scrollY + windowHeight - $('#body_all').height()) / wysokosc) * 100);
        radius = maxRadius - ((percent * maxRadius) / 100);
        setBlur(radius < 0 ? 0 : radius);
    }
}*/

$(".kat_main").hover(
        function () {

            $('.menu_bang_ul').hide();
            $('.kat_main').removeClass('menu_aktiv');

            var v = $(this).attr('data-id');

            $(this).addClass('menu_aktiv');
            var val = $("ul[data-rel='" + v + "']");



            //  $('body').css('overflow','hidden');
            // $('.menu_pionowe_tabs').removeClass('activ');
            $(val).show();

            // $(this).addClass('activ');



        }, function () {

}
);

$(".sub").hover(
        function () {


            $('.sub').children().removeClass('menu_aktiv');

            var v = $(this).attr('data-id');

            $(this).children().addClass('menu_aktiv').css('color', 'white!important');

            var val = $("ul[data-rel='" + v + "']");

        }, function () {

}
);

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

    $("#grupy_blok").css("width" , 'calc(100% - 16px)');
    $("#producenci_blok").css("width" , 'calc(100% - 16px)');
    $("#producenci_blok").css("margin-left" , '8px');
    $(".baner_body").css('position','relative');
    $(".baner_body").css('z-index','0');
    $(".baner_body").css('bottom','auto');
    $("#body_all").css('margin-bottom','0px');
}else{
    $("#grupy_blok").css("width" , 'calc(50% - 14px)');
    $("#producenci_blok").css("margin-left" , '6px');


$('#link_banerek1').hover(
        function () {
            $('#banerek1').toggleClass("niewidoczny");
            $('#banerek1 >div').toggleClass("niewidoczny");
            $('#banerek1 >p').toggleClass("niewidoczny");
            $('#banerek1b').toggleClass("widoczny");
            $('#banerek1b').show();

            $('#link_banerek1').toggleClass("niewidoczny");
            $('#link_banerek1').toggleClass("niewidoczny");
            $('#link_banerek1').hide();


        }, function () {}
);
$('#banerek1b').hover(
        function () {
        }, function () {
            $('#banerek1').removeClass("niewidoczny");
            $('#banerek1>div').removeClass("niewidoczny");
            $('#banerek1>p').removeClass("niewidoczny");
            $('#banerek1b').removeClass("widoczny");
            $('#banerek1b').hide();
            $('#link_banerek1').removeClass("niewidoczny");
            $('#link_banerek1').show();
    }
);

$('#link_banerek2').hover(
        function () {
            $('#banerek2').toggleClass("niewidoczny");
            $('#banerek2 >div').toggleClass("niewidoczny");
            $('#banerek2 >p').toggleClass("niewidoczny");
            $('#banerek2b').toggleClass("widoczny");
            $('#banerek2b').show();
            $('#link_banerek2').toggleClass("niewidoczny");
            $('#link_banerek2').hide();
        }, function () {}
);

$('#banerek2b').hover(
        function () {
        }, function () {
            $('#banerek2').removeClass("niewidoczny");
            $('#banerek2>div').removeClass("niewidoczny");
            $('#banerek2>p').removeClass("niewidoczny");
            $('#banerek2b').removeClass("widoczny");
            $('#banerek2b').hide();
            $('#link_banerek2').removeClass("niewidoczny");
            $('#link_banerek2').show();
}
);

} //detection device
